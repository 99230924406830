<template>
  <svg id="gfs:icon1/info-filled" viewBox="0 0 24 24" v-on="$listeners">
    <path
      d="M12 2c5.519 0 10 4.481 10 10s-4.481 10-10 10S2 17.519 2 12 6.481 2 12 2zm.83 8.33h-1.66v7h1.66v-7zM12.84 7h-1.68l.01 1.7h1.67V7z"
    ></path>
  </svg>
</template>

<script>
export default {};
</script>
